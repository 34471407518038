/* ---------------------
 *** Vendors Scss ***
-----------------------*/

@import "vendors/chartist";
@import "vendors/dropzone";
@import "vendors/scrollable";
@import "vendors/sticky";
@import "vendors/todo";

/* Ngx-owl-carousel-o */
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

/* Toastr */
@import "~ngx-toastr/toastr.css";

/* Dragula */
@import "~dragula/dist/dragula.css";

/* Ngx-datatable */
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

/* Dropzone */
@import "~dropzone/dist/min/dropzone.min.css";

/* Leaflet Map */
@import "~leaflet/dist/leaflet.css";

/* Wizard */
@import "~angular-archwizard/archwizard.scss";


/* Scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* Ng-select */
@import "~@ng-select/ng-select/scss/default.theme.scss";

/* Datetime */
@import "~ng-pick-datetime/assets/style/picker.min.css";

/* Emoji */
@import "~@ctrl/ngx-emoji-mart/picker";

/* ---------------------
	*** Theme Scss ***
-----------------------*/
@import "~animate.css/animate.min.css";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_modal.scss";
@import "style";
@import "responsive";


